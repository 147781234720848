// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-afyabook-js": () => import("./../../../src/pages/afyabook.js" /* webpackChunkName: "component---src-pages-afyabook-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-analytics-js": () => import("./../../../src/pages/data-analytics.js" /* webpackChunkName: "component---src-pages-data-analytics-js" */),
  "component---src-pages-data-analytics-signup-js": () => import("./../../../src/pages/data-analytics-signup.js" /* webpackChunkName: "component---src-pages-data-analytics-signup-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-digital-marketing-signup-js": () => import("./../../../src/pages/digital-marketing-signup.js" /* webpackChunkName: "component---src-pages-digital-marketing-signup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-signup-js": () => import("./../../../src/pages/newsletter-signup.js" /* webpackChunkName: "component---src-pages-newsletter-signup-js" */),
  "component---src-pages-online-pharmacy-demo-js": () => import("./../../../src/pages/online-pharmacy-demo.js" /* webpackChunkName: "component---src-pages-online-pharmacy-demo-js" */),
  "component---src-pages-online-presence-signup-js": () => import("./../../../src/pages/online-presence-signup.js" /* webpackChunkName: "component---src-pages-online-presence-signup-js" */),
  "component---src-pages-patameds-js": () => import("./../../../src/pages/patameds.js" /* webpackChunkName: "component---src-pages-patameds-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-software-application-js": () => import("./../../../src/pages/software-application.js" /* webpackChunkName: "component---src-pages-software-application-js" */),
  "component---src-pages-softwaredev-signup-js": () => import("./../../../src/pages/softwaredev-signup.js" /* webpackChunkName: "component---src-pages-softwaredev-signup-js" */),
  "component---src-pages-usecase-js": () => import("./../../../src/pages/usecase.js" /* webpackChunkName: "component---src-pages-usecase-js" */),
  "component---src-pages-website-design-js": () => import("./../../../src/pages/website-design.js" /* webpackChunkName: "component---src-pages-website-design-js" */),
  "component---src-pages-website-js": () => import("./../../../src/pages/website.js" /* webpackChunkName: "component---src-pages-website-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

